:root{
  --main-color: #6C3721;
  --secondary-color: #e36930;
  --main-color-trans: rgba(108, 55, 33, 0.7);
  --panel-text-color: #ffffff;
}

html, body {
  height: 100%;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
  touch-action: pan-y;
}

/* Everything but the jumbotron gets side spacing for mobile first views */
.header,
.marketing,
.footer {
  padding-left: 15px;
  padding-right: 15px;
}

/* Custom page header */
.header {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 10px;
}
/* Make the masthead heading the same height as the navigation */
.header h3 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 40px;
  padding-bottom: 19px;
}

/* Custom page footer */
.footer {
  padding-top: 19px;
  color: #777;
  border-top: 1px solid #e5e5e5;
}

.container-narrow > hr {
  margin: 30px 0;
}

/* Supporting marketing content */
.marketing {
  margin: 40px 0;
}
.marketing p + h4 {
  margin-top: 28px;
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
  .container {
    max-width: 730px;
  }

  /* Remove the padding we set earlier */
  .header,
  .marketing,
  .footer {
    padding-left: 0;
    padding-right: 0;
  }
  /* Space out the masthead */
  .header {
    margin-bottom: 30px;
  }
  /* Remove the bottom border on the jumbotron for visual effect */
  .jumbotron {
    border-bottom: 0;
  }
}

.view-container {
  padding-top: 0px;
  height: 100%;
}

.mapContainer{
  position: fixed;
  height: 100%;
  width: 100%;
  /*z-index: -1;*/
}

.angular-leaflet-map{
  height: 100%;
}

/**
  Search CSS
**/
#map-menu{
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: var(--main-color);
}

#map-menu .glyphicon.glyphicon-cog, #map-menu .glyphicon.glyphicon-list, #map-menu .glyphicon.glyphicon-globe{
  padding-left: 10px;
  cursor: pointer;
  color: #ffffff;
  font-size: 30px;
}
a.config-option{
  cursor: pointer;
}

/**
  Estilo filtros de busqueda
 */
.panel .panel-default{
  cursor: pointer;
}

.maps-menu .filter-menu, .csv-menu, .real-time, .histogram-btn, .deleteDraw-btn, .resize-btn,
.draw-btn,.extend-map, .pdf-btn, .wordCloud-btn, .trends-btn, .share-btn, .legends, .maps, .historical-tweets, .pins-menu {
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#lateral-menu {
  left: 0;
  position: fixed;
  /*top: 55px;*/
  z-index: 2;
}

#lateral-menu .maps-menu, #lateral-menu .filter-menu, #lateral-menu .csv-menu, #lateral-menu .real-time, #lateral-menu .histogram-btn,
#lateral-menu .gallery-btn, #lateral-menu .webservices-btn, #lateral-menu .deleteDraw-btn, #lateral-menu .resize-btn,
#lateral-menu .draw-btn,#lateral-menu .extend-map, #lateral-menu .pdf-btn, #lateral-menu .wordCloud-btn, #lateral-menu .trends-btn,
#lateral-menu .stadistics-btn, #lateral-menu .share-btn, #lateral-menu .title, #lateral-menu .legends, #lateral-menu .maps,
#lateral-menu .historical-tweets, #lateral-menu .pins-menu{
  background: #333;
  color: #fff;
  display: block;
  height: 35px;
  font: 16px "Open Sans", sans-serif;
  line-height: 35px;
  position: relative;
  text-align: center;
  width: 35px;
  cursor: pointer;
}

#lateral-menu .categories{
  background: #333;
  color: #fff;
  display: block;
  height: 35px;
  font: 16px "Open Sans", sans-serif;
  line-height: 35px;
  position: relative;
  text-align: center;
  width: 95px;
  cursor: pointer;
}

#lateral-menu .maps-menu span, #lateral-menu .filter-menu span, #lateral-menu .csv-menu span, #lateral-menu .real-time span,
#lateral-menu .histogram-btn span, #lateral-menu .gallery-btn span, #lateral-menu .webservices-btn span,
#lateral-menu .deleteDraw-btn span, #lateral-menu .resize-btn span,
#lateral-menu .draw-btn span, #lateral-menu .extend-map span, #lateral-menu .pdf-btn span, #lateral-menu .wordCloud-btn span, #lateral-menu .trends-btn span,
#lateral-menu .stadistics-btn span, #lateral-menu .share-btn span, #lateral-menu .categories span , #lateral-menu .title span, #lateral-menu .legends span,
#lateral-menu .maps span, #lateral-menu .historical-tweets span, #lateral-menu .pins-menu span{
  line-height: 35px;
  left: -250px;
  position: absolute;
  text-align:center;
  width:250px;
}

#lateral-menu .maps-menu:hover .hover, #lateral-menu .filter-menu:hover .hover, #lateral-menu .csv-menu:hover .hover, #lateral-menu .real-time:hover span,
#lateral-menu .histogram-btn:hover .hover, #lateral-menu .gallery-btn:hover .hover, #lateral-menu .webservices-btn:hover span,
#lateral-menu .resize-btn:hover span, #lateral-menu .deleteDraw-btn:hover .hover,
#lateral-menu .draw-btn:hover .hover, #lateral-menu .extend-map:hover span, #lateral-menu .pdf-btn:hover .hover, #lateral-menu .wordCloud-btn:hover .hover,
#lateral-menu .trends-btn:hover span, #lateral-menu .stadistics-btn:hover span,
#lateral-menu .share-btn:hover span, #lateral-menu .categories:hover span, #lateral-menu .title:hover span, #lateral-menu .legends:hover span,
#lateral-menu .maps:hover span, #lateral-menu .historical-tweets:hover span, #lateral-menu .pins-menu:hover .hover{
  left: 100%;
  z-index: 1;
}

.no-allowed {
  cursor: not-allowed;
}

.no-hover{
  pointer-events: none;
}

.selected{
  left: 100% !important;
}

#lateral-menu .title[class*="title"],
#lateral-menu .title[class*="title"]:hover,
#lateral-menu .title[class*="title"] span,
#lateral-menu .categories[class*="categories"],
#lateral-menu .categories[class*="categories"]:hover,
#lateral-menu .categories[class*="categories"] span {background: var(--secondary-color);}

#lateral-menu .maps-menu[class*="maps-menu"],
#lateral-menu .maps-menu[class*="maps-menu"]:hover,
#lateral-menu .maps-menu[class*="maps-menu"] span,
#lateral-menu .deleteDraw-btn[class*="deleteDraw-btn"],
#lateral-menu .deleteDraw-btn[class*="deleteDraw-btn"]:hover,
#lateral-menu .deleteDraw-btn[class*="deleteDraw-btn"] span,
#lateral-menu .resize-btn[class*="resize-btn"],
#lateral-menu .resize-btn[class*="resize-btn"]:hover,
#lateral-menu .resize-btn[class*="resize-btn"] span,
#lateral-menu .draw-btn[class*="draw-btn"],
#lateral-menu .draw-btn[class*="draw-btn"]:hover,
#lateral-menu .draw-btn[class*="draw-btn"] span,
#lateral-menu .maps[class*="maps"],
#lateral-menu .maps[class*="maps"]:hover,
#lateral-menu .maps[class*="maps"] span,
#lateral-menu .legends[class*="legends"],
#lateral-menu .legends[class*="legends"]:hover,
#lateral-menu .legends[class*="legends"] span,
#lateral-menu .extend-map[class*="extend-map"],
#lateral-menu .extend-map[class*="extend-map"]:hover,
#lateral-menu .extend-map[class*="extend-map"] span {background: #2a6b45;}

#lateral-menu .filter-menu[class*="filter-menu"],
#lateral-menu .filter-menu[class*="filter-menu"]:hover,
#lateral-menu .filter-menu[class*="filter-menu"] span,
#lateral-menu .real-time[class*="real-time"],
#lateral-menu .real-time[class*="real-time"]:hover,
#lateral-menu .real-time[class*="real-time"] span,
#lateral-menu .trends-btn[class*="trends-btn"],
#lateral-menu .trends-btn[class*="trends-btn"]:hover,
#lateral-menu .trends-btn[class*="trends-btn"] span {background: #7d4e5e;}


#lateral-menu .pins-menu[class*="pins-menu"],
#lateral-menu .pins-menu[class*="pins-menu"]:hover,
#lateral-menu .pins-menu[class*="pins-menu"] span,
#lateral-menu .csv-menu[class*="csv-menu"],
#lateral-menu .csv-menu[class*="csv-menu"]:hover,
#lateral-menu .csv-menu[class*="csv-menu"] span,
#lateral-menu .historical-tweets[class*="historical-tweets"],
#lateral-menu .historical-tweets[class*="historical-tweets"]:hover,
#lateral-menu .historical-tweets[class*="historical-tweets"] span,
#lateral-menu .pdf-btn[class*="pdf-btn"],
#lateral-menu .pdf-btn[class*="pdf-btn"]:hover,
#lateral-menu .pdf-btn[class*="pdf-btn"] span,
#lateral-menu .wordCloud-btn[class*="wordCloud-btn"],
#lateral-menu .wordCloud-btn[class*="wordCloud-btn"]:hover,
#lateral-menu .wordCloud-btn[class*="wordCloud-btn"] span,
#lateral-menu .gallery-btn[class*="gallery-btn"],
#lateral-menu .gallery-btn[class*="gallery-btn"]:hover,
#lateral-menu .gallery-btn[class*="gallery-btn"] span,
#lateral-menu .histogram-btn[class*="histogram-btn"],
#lateral-menu .histogram-btn[class*="histogram-btn"]:hover,
#lateral-menu .histogram-btn[class*="histogram-btn"] span,
#lateral-menu .webservices-btn[class*="webservices-btn"],
#lateral-menu .webservices-btn[class*="webservices-btn"]:hover,
#lateral-menu .webservices-btn[class*="webservices-btn"] span,
#lateral-menu .stadistics-btn[class*="stadistics-btn"],
#lateral-menu .stadistics-btn[class*="stadistics-btn"]:hover,
#lateral-menu .stadistics-btn[class*="stadistics-btn"] span,
#lateral-menu .share-btn[class*="share-btn"],
#lateral-menu .share-btn[class*="share-btn"]:hover,
#lateral-menu .share-btn[class*="share-btn"] span {background: #7B68EE;}

#map-menu-panel {
  position: fixed;
  z-index: 1;
}

#opt-panel, #csv-panel, #rt-panel, #trend-panel, #gallery-panel,#webservices-panel{
  position: fixed;
  z-index: 1;
}

.real-time .stop{
  z-index: 1;
}

#accordion{
  margin-bottom: 0px;
}

/**
  ultimos rastreos
 */
#lastTrackingUsersTitle, #lastTrackingWordsTitle {
  margin-top: 0px;
  text-align: center;
  font-weight: bold;
}

.trackbtns {
  text-align: center;
}


/**
  Estilo modal config
 */
.config-action-btn{
  color: #ffffff;
  background-color: #cf3c3c;
  border-color: #cf3c3c;
  font-weight: bold;
}

.config-action-btn:hover, .config-action-btn:focus{
  color: #ffffff;
  background-color: #a53030;
}

.tab-row{
  margin-top: 10px;
}

#new-profile-pic{
  display: none;
}
/* Infowindow (Popover) */

.leaflet-popup-content-wrapper{
  width: 600px;
}

.leaflet-popup-content{
  width: auto !important;
  padding-top: 20px;
}

.tweet-sentiment {

  color: white;
  height: 30px;
  font-weight: bold;
  font-size: 16px;
  width: 100px;
  position: absolute;
  right: 75px;
  text-align: center;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin-bottom: 1px;
  border-radius: 3px;
  padding-top: 4px;
}

#clusterContent{
  margin: 0;
  margin-bottom: 2px;
  min-height: 140px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
}

#tweetList{
	/*height: 700px;*/
  	overflow-y: auto;
  	overflow-x: hidden;
}

#tweetList > p {
	font-size: 26px;
}

#tweetList > .userinfo {
	overflow: auto;
}
#tweetList > .userinfo > img {
	float: left;
	margin: 10px;
}
#tweetList > .userinfo > .userdata {
	margin: 10px;
}
#tweetList > .userinfo > .userdata > span {
	display: block;
}
#tweetList > .userinfo > .userdata > span.username {
	font-size: 18px;
	font-weight: bold;
	color: #b74934;
}

.tweet_on_list .userinfo {
	position: absolute;
	bottom: 5px;
	left: 125px;
	overflow-y: hidden;
}

/**
  Alerts
**/

.over_message_container{
  padding: 50px !important;
  background-color: #ffffff !important;
  border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  -webkit-border-radius: 15px !important;
  border: none !important;
}

.myCluster{
  background-color: transparent;
  z-index: -1;
}


/*Bouncing Pin*/
img.leaflet-marker-icon{
  animation:bounce 0.5s;
  z-index: 133 !important;


}

@keyframes bounce{
  0%{

    top: -40px;
  }
  10%{
    top:-30px;
  }

  20%{
    top: -20px;
  }

  30%{
    top: -10px;
  }
  40%{
    top:0px;
  }

  50%{
    top:-10px
  }

  60%{

    top:-15px;
  }

  70%{
    top:-10px ;
  }

  80%{
    top: -5px;
  }

  100%{
    top:0px;
  }
}


/*Pop UP*/
.leaflet-popup-content{
  margin:0 0 0 0;
  padding-top: 0;
}

/*TweetList Real Time*/
#list_header{
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 16px;
  border-radius: 10px 10px 0 0 ;
  background-color: var(--main-color);
  padding: 10px 0 10px 0;
}

#TweetListContent{
  position: absolute;
  z-index: 1;
  margin-left: 25px;
  margin-top: 10px;
  height: 70%;
  opacity: 0.7;
 /* max-height:470px;*/

}

#TweetListContent:hover{
  opacity: 0.9;
}

#info_tweetList{
 padding-left: 50px;
 padding-right: 3px;
 border-bottom: 2.5px solid lightgray;
}


/*Tamaño de clusters de acuerdo al numero de pines */
.clustersmall {
  border-radius: 20px;
  width: 20px;
  height: 20px;
  margin-left: 5px;
  margin-top: 5px;
  padding-top: 7px;
  /*background-color: greenyellow;*/
  background-clip: padding-box;
  background-color: rgba(181, 226, 140, 1);
  text-align: center;
  font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
  z-index: -999 !important;
}

.clustermedium {
  z-index: -999 !important;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  margin-left: 5px;
  margin-top: 5px;
  /*background-color: #ffdb58;*/
  background-clip: padding-box;
  background-color: rgba(241, 211, 87, 1);
  text-align: center;
  padding-top: 13px;
  box-sizing: border-box;
  font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.clusterbig {
  z-index: -999 !important;
  border-radius: 30px;
  width: 60px;
  height: 60px;
  margin-left: 5px;
  margin-top: 5px;
  /*background-color: #ffdb58;*/
  background-clip: padding-box;
  background-color: rgba(253, 156, 115, 1);
  text-align: center;
  padding-top: 20px;
  box-sizing: border-box;
  font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}

/** Tweet Content **/
#tweet_followed_cluster{
    color: white;
    padding: 0;
    height: 33px;
    width: 34px;
    margin-top: 4px;
    position: relative;
    background-color: rgba(0,0,0,0.5);
}

#tweet_followers_cluster{
    color: white;
    padding: 0;
    height: 33px;
    width: 34px;
    margin-top: 4px;
    position: relative;
    background-color: rgba(0,0,0,0.5);
}

#track_user_cluster{
  color: white;
    padding: 0;
    height: 33px;
    width: 34px;
    margin-top: 4px;
    position: relative;
    background-color: rgba(0,0,0,0.5);
    padding-left: 0.5%;
}



#track_user_cluster:hover , #track_user:hover {
  color: black;
}

.subIconFollowers{
    font-size: 17px !important;
    text-align: left;
    /* color: black; */
    text-shadow: 2px 0px 5px #000;
    padding-right: 12px;
}

#tweet_info_cluster{
  margin-top: 4px;
  background: rgba(0,0,0,0.5);
  color: white;
  position: relative;

}

#expand_historical{
    margin-left: 83%;
    position: absolute;
    background:transparent;
    color: gray;
    z-index: 10;
}

#expand_historical:hover, #reduce_historical:hover{
  color: black;
}

#reduce_historical{
    /*margin-left: 83%;*/
    margin-left: calc(100% - 7%);
    position: absolute;
    background:transparent;
    color: gray;
    z-index: 10;
}


#close_expand_historical{
    margin-left: 90%;
    position: absolute;
    background:transparent;
    color: gray;
    outline: none;
    z-index:10;
}



#close_reduce_historical{
    /*margin-left: 93%;*/
    margin-left: calc(100% - 4%);
    position: absolute;
    background:transparent;
    color: gray;
    outline: none;
    z-index:10;
}

#close_reduce_historical:hover, #close_expand_historical:hover{
  color: black;
}

#tweet_info_cluster:hover,
#tweet_img:hover, #tweet_info_list:hover,
#tweet_followed_cluster:hover, #tweet_followers_cluster:hover {
    color: black;
}

#tweet_info_cluster:hover{
  color: black;
}

.sentiment_amount{
  text-align: center;
  font-size: 16px;
  text-shadow:
       1px 1px 0 #fff,
     -1px -1px 0 #fff,
      1px -1px 0 #fff,
      -1px 1px 0 #fff,
       1px 1px 0 #fff;
}

.detail_tweet{

}

/*.jsonview .string{
  color:green;
  white-space: pre-wrap;
}

.jsonview .num , .jsonview .bool{
  color: blue;
}

.jsonview .null{
  color: red;
}

.jsonview ul{
  list-style: none;
  margin:0 0 0 2em;
  padding: 0;

}

.jsonview .collapser{
  left: 3.8em;
  cursor: pointer;
  margin-right: -5px;
margin-left: -13px;
}

.jsonview .prop{
  font-weight: bold;
}

.jsonview .listhead{
  font-weight: bold;
}

.jsonview .q{
  width: 0px;
  color: transparent;
}

.jsonview .collapsible{
      transition: width 1.2s;
      transition: height 1.2s;
}

.jsonview{
  font-size: 1.1em;
  font-family: monospace;
}*/

.tree{
  padding: 20px;
  height: 300px;
  overflow-y: auto;
  /*overflow-x: hidden;*/
  display: block;
}

.json-formatter-row .string{
  white-space: normal;
}

.disable{
  pointer-events: none;
  opacity: 0.7;
  cursor: not-allowed !important;
}

figure
{
    width:25%;
    float:left;
    margin:0px;
    text-align:center;
    padding:0px;
}

#sentiment-option{
  text-align: center;
  font-weight: bold;
}

#sentiment-option:hover{
  background-color: #F78181;
  color: white;

}

.sentiments{
  z-index: -999 !important;
}

#normal-option{
  text-align: center;
  font-weight: bold;
  padding-top: 15px;
}

#normal-option:hover{
  color: white;
  background-color: #58ACFA;

}

#input_normal{
      margin-top: 45px ;
    margin-left: 50px ;
    height: 45px;
    width: 45px;
}



#modal_body{
  height:auto;
}

.tweet-sentiment-list{
  color: white;
  height: 30px;
  font-weight: bold;
  font-size: 16px;
  width: 100px;
  float: right;
  right: 5px;
  text-align: center;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin-bottom: 1px;
  border-radius: 3px;
  padding-top: 4px;
}

.custom-scrollModal {
  overflow-y: auto;
  max-height: 400px;
}

.gallery-modal{
  overflow-y: auto;
  max-height: 600px;
  background-color: var(--main-color);
}

#historico-tweet{
    z-index: 2;
    position: absolute;
    background-color: #89a9a9;
    border: 1px solid;
    border-radius: 10px;
    top:28%;
    right: 0;
    opacity: 0.50;
}

#historico-tweet:hover{
    background-color: #89a9a9;
    opacity: 0.85;
}

.inRT{
  margin-left:180px ;

}
.inNormal{
  margin-left: 60px;
}

input[type="image"]{
  outline: none;
}

#historical_expanded{
  z-index: 2;
  border: 1px solid;
  border-radius: 10px;
  height: 450px;
  width: 650px;
  position: absolute;
  background-color: #89a9a9;
    opacity: 0.50;
    top: 155px;
    left: 665px;;

}

#historical_expanded:hover{
      background-color: #89a9a9;
    opacity: 0.85;
}


/* Popup container - can be anything you want */
.popup {
    position: relative;
    display: inline-block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* The actual popup */
/*#Streets .popuptext {
    visibility: hidden;
    width: 200px;
    height: 140px;
    right:125%;
    background-color: #555;
    color: #fff;
    text-align: center;
    border: 3px solid black;
    position: absolute;
    z-index: 1;
    bottom: -600%;
}

#cycle .popuptext {
    visibility: hidden;
    width: 200px;
    height: 140px;
    right:125%;
    background-color: #555;
    color: #fff;
    text-align: center;
    border: 3px solid black;
    position: absolute;
    z-index: 1;
    bottom: -443%;
}*/


#close_cloud{
    margin-left: 95%;
    position: absolute;
    background:transparent;
    color: gray;
    outline: none;
    z-index:10;
}


#close_cloud_general{
    margin-left: 90%;
    position: absolute;
    background:transparent;
    color: gray;
    outline: none;
    z-index:10;
}

/** nube de palabras */
#close_cloud:hover{
  color: black;
}

#nube-palabras-mapa{
    z-index: 1;
    position: absolute;
    background-color: #89a9a9;
    border: 1px solid;
    border-radius: 10px;
    top:28%;
    right: 0;
    opacity: 0.50;
}

#nube-palabras-mapa:hover{
    background-color: #89a9a9;
    opacity: 0.85;
}

/* anima el icono de generar nube de palabras */
.glyphicon-spin {
    -webkit-animation: spin 1000ms infinite linear;
    animation: spin 1000ms infinite linear;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

/** fin animacion icono de generar nube de palabras */






#Streets .popuptext {
  visibility: hidden;
  width: 200px;
  height: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border: 3px solid black;
  position: absolute;
  z-index: 1;
  top: -40px;
  left: 140px;
}

#cycle .popuptext {
  visibility: hidden;
  width: 200px;
  height: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border: 3px solid black;
  position: absolute;
  z-index: 1;
  top: -55px;
  left: 142px;
}

#traffic .popuptext {
  visibility: hidden;
  width: 200px;
  height: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border: 3px solid black;
  position: absolute;
  z-index: 1;
  top: -70px;
  left: 152px;
}

#night .popuptext {
  visibility: hidden;
  width: 200px;
  height: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border: 3px solid black;
  position: absolute;
  z-index: 1;
  top: -75px;
  left: 158px;
}

#satellite .popuptext {
  visibility: hidden;
  width: 200px;
  height: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border: 3px solid black;
  position: absolute;
  z-index: 1;
  top: -105px;
  left: 138px;
}


.popup .popuptext::after {
    content: "";
    position: absolute;
    top: 50%;
    right:-6%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #555;
}



/* Toggle this class - hide and show the popup */
/*#Streets:hover .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}*/
#Streets:hover .show,
#cycle:hover .show,
#traffic:hover .show,
#night:hover .show,
#satellite:hover .show {
  visibility: visible;
  -webkit-animation: fadeIn 0.3s;
  animation: fadeIn 0.3s;
}


/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity:1 ;}
}




/*Histogram Button*/
  #histogram-button{
    position: absolute;
    z-index: 1;

  }

/*****************/

#Modal-histogram-options{
  margin-top: 75px;
}

.usr-img-twitter-cluster{
  left:15px;
  top:27px;
  position: relative;
  float: left;
}

.border-positivo-cluster{
  border-bottom: #35BC7A solid 3px;
  border-left: solid 5px #35BC7A;
}

.border-neutro-cluster{
  border-bottom: #feda69 solid 3px;
  border-left:  solid 5px #feda69;
}

.border-negativo-cluster{
  border-bottom: #F54C48 solid 3px;
  border-left: solid 5px #F54C48;
}

.sentiment-container-cluster{
  margin-left: -15px;
  margin-top: 7%;
  padding-top: 4px;
  color: white;
}



.sentiment-container-cluster span{
  font-size: 16px;
  font-weight: bold;
  right: 10px;
  z-index: 1;
  position: absolute;
}

.sentiment-neutro-cluster span{
  right: 23px;
}

.sentiment-positivo-cluster::before{
  content: '';
  position: absolute;
  border-style: solid;
  border-radius: 5px;
  border-width: 12px 16px 12px 7px;
  border-color: #35BC7A;
  display: block;
  width: 0px;
  z-index: 1;
  right: -6px;
  top: 74%;
  transform: rotateY(1deg) rotateZ(-45deg);
}



.sentiment-neutro-cluster::before{
  content: '';
  position: absolute;
  border-style: solid;
  border-radius: 5px;
  border-width: 12px 16px 12px 7px;
  border-color: #feda69;
  display: block;
  width: 0px;
  z-index: 1;
  right: -6px;
  top: 74%;
  transform: rotateY(1deg) rotateZ(-45deg);
}

.sentiment-negativo-cluster::before{
  content: '';
  position: absolute;
  border-style: solid;
  border-radius: 5px;
  border-width: 12px 16px 12px 7px;
  border-color: #F54C48;
  display: block;
  width: 0px;
  z-index: 1;
  right: -6px;
  top: 74%;
  transform: rotateY(1deg) rotateZ(-45deg);
}

.sentiment-neutro {
    background-color:  #feda69;
}

.sentiment-negativo {
    background-color: #F54C48;
}

.sentiment-positivo {
    background-color: #35BC7A;
}

.sentiments-neutro {
    border: 70px solid transparent;
    border-top-color: #feda69;
    border-bottom: 0;
    border-right: 0;
    float: right;
}

.sentiments-negativo {
    border: 70px solid transparent;
    border-top-color: #F54C48;
    border-bottom: 0;
    border-right: 0;
    float: right;
}

.sentiments-positivo {
    border: 70px solid transparent;
    border-top-color: #35BC7A;
border-bottom: 0;
border-right: 0;
float: right;
}

.leaflet-popup-pane{
  margin-top: -13px;
}

.custom .leaflet-popup-content-wrapper{
overflow: hidden;
  max-height: 375px;
  padding: 5px 15px 10px 5px;
}

/*css for the popup of the clusters*/
.cluster-container.no-scroll{
  padding-right: 15px !important;
}

.clusterPopupContainer .leaflet-popup-content-wrapper{
  background: none;
  box-shadow: none;
}

.clusterPopupContainer .leaflet-popup-content{
  margin: 0;
}

.clusterPopupContainer .has-scroll-top:after{
  content: "";
  height: 23px;
  width: 585px;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(255, 255, 255, 0));
  display: block;
  margin-top: -20px;
  position: relative;
  /*position: absolute;*/
  border-radius: 14px 14px 0px 0px;
  /*top: 35px;*/
  top:-435px;
  right: 3px;
  z-index: 1;
}

.clusterPopupContainer .has-scroll:before{
  content: "";
  height: 23px;
  width: 585px;
  background: linear-gradient(rgba(255, 255, 255, 0),rgba(0, 0, 0, 0.8));
  display: block;
  margin-top: 10px;
  position: relative;
  border-radius: 0px 0px 14px 14px;
  /*top: 280px;*/
  top: 405px;
  right: 3px;
  z-index: 1;
}

.cluster-container .clusterTweet{
  background-color: #ffffff;
  padding: 3px;
  margin-bottom: 5px;
  margin-right: 5px;
  border-radius: 12px;
}
.clusterPopupFooter{
  background: linear-gradient(45deg,  transparent 12px, #b2b2b2 0) bottom left;
  color: #e36930;
  font-weight: bold;
  font-size: 15px;
  padding: 10px 5px 10px 5px;
  margin-top: 5px;
}

.popupTweetBtnFooter{
  cursor: pointer;
  margin-left: 10px;
}
.closeClusterPopupBtn{
  background-color: transparent;
  border: none;
  font-size: 12px;
}

.cluster-container{
  overflow: auto;
  /*max-height: 275px;*/
  max-height: 400px;
  overflow-x: hidden;
}

.cluster-container::-webkit-scrollbar{
  background-color: #ffffff;
  border-radius: 20px;
  width: 10px;
}

.cluster-container::-webkit-scrollbar-track{
  border-radius: 20px;
}

.cluster-container::-webkit-scrollbar-thumb{
  background-color: #A6A6A6;
  border: 2px solid #ffffff;
  border-radius: 10px;
}

.cluster-row{
  padding-top: 10px;
  padding-bottom: 6px;
}

.cluster-container .tweet_link{
  color: white;
}

.cluster-container #tweet_text{
  font-size: 17px;
  word-wrap: break-word;
}
/*End css for the popup of the clusters*/

 .username-container{
  font-size: 18px;
   font-weight: bold;
   border-radius: 5px;
   color: white;
   background: rgba(0,0,0,0.5);
   padding: 3px;
   /*clear: right;*/
   margin-left: 34px;
}


.userdata-cluster{
  margin: 0px 10px 10px 15px;
}


/*
.leaflet-popup-content{

}

.leaflet-popup-content-wrapper{
  overflow: scroll;
  overflow-x: hidden;
}*/


/*.ng-scope {
  border: 1px solid red;
  margin: 3px;
}*/


/* para los clusters pie chart */
#canvas-holder {
	width: 100%;
	margin-top: 50px;
	text-align: center;
}
#chartjs-tooltip {
	opacity: 1;
	position: absolute;
	background: rgba(255, 255, 255, .7);
	color: black;
	border-radius: 3px;
	-webkit-transition: all .1s ease;
	transition: all .1s ease;
	pointer-events: none;
	-webkit-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
}
.chartjs-tooltip-key {
	display: inline-block;
	width: 10px;
	height: 10px;
	margin-right: 10px;
}
/* ----------------------------- */

.dropdown-submenu{
  position:relative;
}

.dropdown-submenu>.dropdown-menu{
  top:31px;
  margin-top:-6px;
  margin-left:-1px;
  -webkit-border-radius:0 6px 6px 6px;
  -moz-border-radius:0 6px 6px 6px;border-radius:0 6px 6px 6px;
}
.dropdown-submenu:hover>.dropdown-menu{
  display:block;
}


.dropdown-submenu.pull-left{
  float:none;
}

.dropdown-submenu.pull-left>.dropdown-menu{
  left:-100%;
  margin-left:10px;
  -webkit-border-radius:6px 0 6px 6px;
  -moz-border-radius:6px 0 6px 6px;border-radius:6px 0 6px 6px;
}

ul[class="dropdown-menu"] li{
  text-align: center;
}

.custom-panel{
  border-radius: 0;
  border-width: 0;
  margin-top: 0;
  text-align: center;
  margin-top: 2px !important;
}

.custom-panel h4{
  font-weight: bold !important;

}

#tweet_info_list{
    margin-top: 5px;
  background: rgba(0,0,0,0.5);
  color: white;
}

.panel-search-options{
  overflow: auto;
}

#mouse-tooltip {
  font-size: 75%;
  font-weight: bold;
  background: #60667b;
  color: whitesmoke;
  opacity: 0.8;
}

.leaflet-draw-tooltip {
  display: none;
}

#anchor-twitter-list:hover, #anchor-twitter-list:focus{
  cursor :pointer;
  text-decoration:  none !important;
  outline: none;
}

.custom-modal-header {
  background: var(--secondary-color);
  color: whitesmoke;
  font-weight: bold;
}

.close {
  color: whitesmoke;
}



/*sidebar*/
.wrapper {
    display: block;
}

#sidebar {
    min-width: 250px;
    max-width: 250px;
    /*height: 100vh;*/
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    /* top layer */
    z-index: 9999;
    background: var(--main-color);
    color: #fff;
    display: block;
    animation-name: In;
    animation-duration: .25s;
}

#sidebar.active {
    left: -22%;
    display: none;
}

@keyframes In {
    0%   {left: -17.6%; opacity:0;}
    13%  {left: -15.4%; opacity: .15;  }
    25%  {left: -13.2%; opacity:.3;}
    37%  {left: -11%; opacity: .45 }
    50%  {left: -8.8%; opacity:  .6}
    63%  {left: -6.6%; opacity: .75}
    75%  {left: -4.4%; opacity: .90}
    86%  {left: -2.2%; opacity: .95}
    100% {opacity: 1; left: 0}
}


#sidebar ul.components{
    padding: 20px 0;
    border-bottom: 1px solid var(--secondary-color);
}

#sidebar ul p {
    padding: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 2em;
}

#sidebar a{
  color:white;
  font-weight: bold;
  font-size: 16px;
}

#sidebar a:hover, #sidebar a:focus{
  color: var(--secondary-color);
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

/*.overlay {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    z-index: 9998;
}*/

.leaflet-container a.leaflet-popup-close-button{
  z-index: 2;
  padding: 3px 24px 0 0;
  color: #6b6b6b;
  font: 2em Tahoma, Verdana, sans-serif;
}

#dismiss {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  background: var(--main-color);
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.config-modal-button{
  background-color: var(--main-color);
  float: left;
}

/*.showOverlay{
  display: block;
}*/

/* Histogram CSS*/
/* .c3-line-Positivo, .c3-line-Negativo, .c3-line-Neutro {
  stroke-width:4px !important;
} */

.c3-line{
  stroke-width:4px !important;
}

#histogramBreadCrumb>li>a{
  color: black;
  font-weight: bold;
}

.hideAnchor>a{
  display: none;
}

.noUserNameBackground{
  background: rgba(0,0,0,0);
}

span[class="string url"]{
  pointer-events: none;
   cursor: default;
}

.ws-option, .gallery-option{
  padding: 10px !important;
  margin-left: 0;
  margin-right: 0;
  cursor: pointer;

}

.ws-option:hover, .gallery-option:hover{
  background-color: lightgray;
}

.dropmenu_ws_cluster{
    height: 30px;
    font-weight: bold;
    font-size: 16px;
    /* width: 100px; */
    right: 4%;
    text-align: center;
    text-overflow: ellipsis;
    margin-bottom: 1px;
    border-radius: 3px;
    padding-top: 4px;
    float: right;
}

.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
  min-width: 100%;
}
.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
background-color: #e2e2e2;
    left: 6%;
  margin-left: -1.5px;
}
.timeline > li {
  margin-bottom: 20px;
  position: relative;
  width: 90%;
  float: left;
  clear: left;

}
.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}
.timeline > li:after {
  clear: both;
}
.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}
.timeline > li:after {
  clear: both;
}
.timeline > li > .timeline-panel {
  width: 95%;
  float: left;
  border: 1px solid #d4d4d4;
  /*border-radius: 2px;*/
  /*padding: 20px;*/
  position: relative;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
}

.timeline > li > .timeline-panel:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #ccc;
  border-right: 0 solid #ccc;
  border-bottom: 15px solid transparent;
  content: " ";
}
.timeline > li > .timeline-panel-positivo:before {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #35BC7A;
  border-bottom: 14px solid transparent;
  content: " ";
}

.timeline > li > .timeline-panel-negativo:before {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #F54C48;
  border-bottom: 14px solid transparent;
  content: " ";

}

.timeline > li > .timeline-panel-neutro:before {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #feda69;
  border-bottom: 14px solid transparent;
  content: " ";

}

.timeline > li > .timeline-badge {
  color: #fff;
  width: 24px;
  height: 24px;
  line-height: 50px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 16px;
  right: -12px;
  /*background-color: #999999;*/
  /*z-index: 100;*/
  /*
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  */
}
.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}
.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}


.timeline > li.timeline-inverted{
  float: right;
  clear: right;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 4%;
}

.timeline > li.timeline-inverted > .timeline-badge{
  left: -12px;
}

/*@media (max-width: 767px) {
    ul.timeline:before {
        left: 40px;
    }

    ul.timeline > li {
      margin-bottom: 20px;
      position: relative;
      width:100%;
      float: left;
      clear: left;
    }
    ul.timeline > li > .timeline-panel {
        width: calc(100% - 90px);
        width: -moz-calc(100% - 90px);
        width: -webkit-calc(100% - 90px);
    }

    ul.timeline > li > .timeline-badge {
        left: 28px;
        margin-left: 0;
        top: 16px;
    }

    ul.timeline > li > .timeline-panel {
        float: right;
    }

        ul.timeline > li > .timeline-panel:before {
            border-left-width: 0;
            border-right-width: 15px;
            left: -15px;
            right: auto;
        }

        ul.timeline > li > .timeline-panel:after {
            border-left-width: 0;
            border-right-width: 14px;
            left: -14px;
            right: auto;
        }

.timeline > li.timeline-inverted{
  float: left;
  clear: left;
  margin-top: 30px;
  margin-bottom: 30px;
}

.timeline > li.timeline-inverted > .timeline-badge{
  left: 28px;
}
}*/

.widgetContainer{
  height: auto;
  border-radius: 20px;
}

.widget_init{
    margin: 0 auto;
    text-align: center;
    margin-right: 1%;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 1px 0px #06060d;
}

.widget {
    margin: 0 auto;
    margin-top: 1.3%;
    text-align: center;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 1px 0px #06060d;
}

.widgetHeader{
    background-color: var(--main-color);
    height:42px;
    color:#929DAF;
    text-align: center;
    line-height: 40px;
    border-radius: 5px 5px 0 0;
    font-weight: 400;
    font-size: 1.5em;
    /*text-shadow: 1px 1px #06060d;*/
}
.percent{
  font-weight: bold;
}

.carousel-indicators li{
    background-color: rgba(39, 34, 34, 0.3);
}

.carousel-indicators .active{
  background-color: black;
}

.card{
  width: 17em;
  border: 3px lightgray solid;
  border-radius: 8px;
  overflow: hidden;
  max-height: 349px;
  height:349px;
  position: relative;
}

.card-block{
      border-top: #55acee 4px solid;
}

.twitter-icon{
      position: absolute;
    font-size: 1.9em;
    color: white;
    background-color: #55acee;
    border-radius: 100px;
    padding-left: 10px;
    padding-right: 7px;
    left: 39%;
    top: 55%;
    padding-top: 5px;
}

/*.hexagon {
    overflow: hidden;
    visibility: hidden;
    -webkit-transform: rotate(120deg);
       -moz-transform: rotate(120deg);
        -ms-transform: rotate(120deg);
         -o-transform: rotate(120deg);
            transform: rotate(120deg);
    cursor: pointer;
    }
.hexagon-in1 {
    overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(-60deg);
       -moz-transform: rotate(-60deg);
        -ms-transform: rotate(-60deg);
         -o-transform: rotate(-60deg);
            transform: rotate(-60deg);
    }
.hexagon-in2 {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50%;
    background-image: url(http://placekitten.com/240/240);
    visibility: visible;
    -webkit-transform: rotate(-60deg);
       -moz-transform: rotate(-60deg);
        -ms-transform: rotate(-60deg);
         -o-transform: rotate(-60deg);
            transform: rotate(-60deg);
    }

  .dodecagon {
        width: 90px;
    height: 90px;
    position: absolute;
    left: 30%;
    top: 3%;
    }*/

.track_user_button{
    background-color: #55acee ;
    border-color: #b3daf7 ;
    border-radius: 64px;
    margin-top: 37%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 18px;

}

.track_user_button:focus, .btn:active:focus{
  outline: none;
}

.track_user_button:hover{
      background-color: #31b0d5;
    border-color: #1b6d85;
}

.img-usr{
    height: 56%;
    width: 53%;
    border-radius: 101px;
    overflow: hidden;
    position: relative;
    border: rgba(133, 195, 243, 0.5) 5px solid;
    left: 24%;
    top: 4%;
}

.card:hover{
  border: 3px rgba(0,0,0,0.7) solid;
}

.image-container-followed{
      border-bottom: #55acee 4px solid;
}

.user-followed-name{
  position: absolute;
    top: 39%;
    width: 100%;
    color: white;
    background-color: rgba(0,0,0,0.35);
}

.userscreenname_modal a{
  color: white;
}

.listControls:hover{
  cursor: pointer;
  color:black;
}

.listControls{
  font-size: 3em;
  color: white;
}


.trends-options {
  margin-left: 0;
  margin-right: 0;
  cursor: pointer;
}

.trends-options:hover {
  background-color: lightgray;
}

.trends-options:hover .dropdown-content {
  display:block;
}

.trends-panel {
  padding-left: 0px;
  padding-right: 0px;
  /*max-height: 190px;
  overflow-y: scroll;*/
}

.dropdown-content {
  width: 250px;
  word-break: break-all;
  display: none;
  position: fixed;
  margin-top: -44px;
  margin-left: 250px;
  background-color: #f9f9f9;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 10px 0px;
  z-index: 1;
}

.custom-left-margin {
  margin-left: 45px;
}

.mapSize{
  background-size: cover;
  height: 200px;
  width: 315px;
}

.mapSize .leaflet-control-container{
  display: none;
}


.mapTitleOption{
  background-color: rgba(115, 115, 115, 0.5);
  border-radius: 10px;
  color: white;
  font-weight: bold;
  padding: 10px;
  position: relative;
  text-align: center;
  top: -21%;
  z-index: 1;
}

.progress{
  background-color:rgba(70,120,145,0.7);
  margin-bottom: 5px;
}


/*tooltips de la nube de palabras */
.tctooltip {
	border: 1px solid #000;
	background-color: #fc6;
	color: #000;
	padding: 4px;
	font-size: 80%;
	max-width: 150px;
	text-align: center;
	border-radius: 3px
}

.leaflet-control-layers-minimap{
 visibility: hidden;
}

.showController{
  visibility: visible !important ;
}

.c3-grid line{
  stroke: #2f2f2f !important;
}

.leaflet-minimap-label{
  height: auto !important;
}
/**CCS InfoLegend **/
#infoLegend{
  position: absolute;
  bottom: 0;
  right: 0px;
  margin-right: 10px;
  /*width: 11.5%;*/
  width: 215px;
  padding-left: 25px;
}

.legendContainer{
  padding-bottom: 2px;
  margin-left: 15px;
}

.legendInfo{
  text-align: center;
  padding: 0;
}

/** CCS Search InfoBox **/
#infoSearch{
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  width: 42%;
}

#info{
  color: #ffffff;
}

.infoBox{
  background-color: var(--main-color-trans);
  padding: 5px 5px 0px 5px;
  margin-bottom: 5px;
  border-radius: 5px;
}

#anchor-twitter-list{
  color: #ffffff;
}

#heatSlider{
  display: none;
}

.mapControl{
  width: auto;
  height: auto;
  position: absolute;
  right: 0;
  top:10em;

}

.controlContainer{
  width: auto;
  overflow-y: scroll;
  max-height: 67vh;
  /*background-color: white;*/
  border-radius: 8px;
  padding: 3px;
  padding-right: 5px;
}

.controlContainer::-webkit-scrollbar{
  background-color: #ffffff;
  border-radius: 20px;
  width: 10px;
}

.controlContainer::-webkit-scrollbar-track{
  border-radius: 20px;
}

.controlContainer::-webkit-scrollbar-thumb{
  background-color: #A6A6A6;
  border: 2px solid #ffffff;
  border-radius: 10px;
}

.baseMap{
  height: 158px;
  width: 296px;
  padding:3px;
  cursor: pointer;
  background-color: white;
  border-radius: 2px;
}

.mapSelector input[type="radio"]{
  margin-right: 2px;
  vertical-align: sub;
}

.mapSelector span{
  font-weight: bold;
}


.baseMap img{
  border-radius: 8px;
}

.mapSelector{
  position: relative;
  bottom: 2em;
  padding: 4px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
}

@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) {
  #infoSearch{
    width: 60%;
  }

  #stadisticsCol{
    height: 65vh !important;
  }

  .search-info-tweetList{
    padding-top: 7px !important;
  }

  .container-tweetsList{
    height: 78.5vh !important;
  }

  .realTimeTweetContainer{
    max-height: 400px !important;
  }

  .collapseView .limit{
    width: 500px !important; 
  }

  .realTimeTweetList{
    width: 500px !important;
  }

  .searchFilterBar .searchFilterTitleBar, .searchFilterBar .searchFilterDataBarLeft,
  .searchFilterBar .searchFilterDataBarRight, .searchFilterBar .searchFilterDataBarCenter{
    border-radius: 0px 0px 0px 0px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .searchFilterBar{
    left: 36.1% !important;
  }
}

@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {
  #infoSearch{
    width: 65%;
  }

  .tweetListContainer{
    margin-left: 25px;
  }

  .container-tweetsList{
    margin-left: 25px;
    height: 63vh !important;
  }

  .search-info-tweetList{
    margin-left: 40px !important;
    margin-right: 25px !important;
  }

  .custom-left-margin {
    margin-left: 10px;
  }

  .collapseView .limit{
    width: 400px !important; 
  }

  .realTimeTweetList{
    width: 400px !important;
  }

  .searchFilterBar .searchFilterTitleBar, .searchFilterBar .searchFilterDataBarLeft,
  .searchFilterBar .searchFilterDataBarRight, .searchFilterBar .searchFilterDataBarCenter{
    border-radius: 0px 0px 0px 0px !important;
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .searchFilterBar{
    left: 51.1% !important;
  }
}

/** CSS ListView **/
#stadisticsCol{
  height: 75vh;
  overflow: hidden;
  margin-top: 16px;
}

#stadisticsCol::-webkit-scrollbar{
  background-color: #ffffff;
  border-radius: 20px;
  width: 10px;
}

#stadisticsCol::-webkit-scrollbar-track{
  border-radius: 20px;
}

#stadisticsCol::-webkit-scrollbar-thumb{
  background-color: #A6A6A6;
  border: 2px solid #ffffff;
  border-radius: 10px;
}

.scrollable{
  /*overflow-y: scroll !important;*/
  overflow-y: auto !important;
}

#donutContainer, #nubePalabrasPos, #nubePalabrasNeg, #nubePalabrasGral{
  margin-left: 45px;
}

#myCanvasPos, #myCanvasNeg, #myCanvasGral{
  width: 50%;
}

.search-info-tweetList{
  padding-top: 10px;
  color: #FFFFFF;
  margin-left: 45px;
}

.tweetListContainer{
  background-color: var(--main-color);
  color: whitesmoke;
  margin-top: 2.06%;
  padding: 4px;
  border-radius: 5px 5px 0 0;
  margin-right: 10px;
  /*New*/
  margin-bottom: 3px;
  margin-top: 16px;
  text-align: center;
  line-height: 32px;
  font-weight: 400;
  font-size: 1.5em;
}

.tweetListContainer .list-header{
  font-size: 16px;
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.container-tweetsList{
  /*background-color: white;*/
  background-color: none;
  height: 85vh;
  overflow: auto;
  overflow-x:hidden;
  margin-right: 10px;
}
.container-tweetsList .clusterTweet{
  background-color: #ffffff;
  padding: 3px;
  margin-bottom: 5px;
  margin-right: 5px;
  border-radius: 12px;
}


.container-tweetsList::-webkit-scrollbar{
  background-color: #ffffff;
  border-radius: 20px;
  width: 10px;
}

.container-tweetsList::-webkit-scrollbar-track{
  border-radius: 20px;
}

.container-tweetsList::-webkit-scrollbar-thumb{
  background-color: #A6A6A6;
  border: 2px solid #ffffff;
  border-radius: 10px;
}

.breadcrumb li a{
  cursor: pointer;
}

.clipboardButton{
    float: right;
    position: relative;
    display: none;
}

.tree:hover .clipboardButton{
  display: block;
}

.funkyradio div {
  clear: both;
  overflow: hidden;
}
.funkyradio label {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #D1D3D4;
  font-weight: normal;
}

.funkyradio input[type="radio"]:empty,
.funkyradio input[type="checkbox"]:empty {
  display: none;
}

.funkyradio input[type="radio"]:empty ~ label,
.funkyradio input[type="checkbox"]:empty ~ label {
  position: relative;
  line-height: 2.5em;
  text-indent: 3.25em;
  /*margin-top: 2em;*/
  margin-bottom: 0em;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.funkyradio input[type="radio"]:empty ~ label:before,
.funkyradio input[type="checkbox"]:empty ~ label:before {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  content: '';
  width: 2.5em;
  background: #D1D3D4;
  border-radius: 3px 0 0 3px;
}

.funkyradio input[type="radio"]:hover:not(:checked) ~ label,
.funkyradio input[type="checkbox"]:hover:not(:checked) ~ label {
  color: #888;
}

.funkyradio input[type="radio"]:hover:not(:checked) ~ label:before,
.funkyradio input[type="checkbox"]:hover:not(:checked) ~ label:before {
  content: '\2714';
  text-indent: .9em;
  color: #C2C2C2;
}

.funkyradio input[type="radio"]:checked ~ label,
.funkyradio input[type="checkbox"]:checked ~ label {
  color: #777;
}

.funkyradio input[type="radio"]:checked ~ label:before,
.funkyradio input[type="checkbox"]:checked ~ label:before {
  content: '\2714';
  text-indent: .9em;
  color: #333;
  background-color: #ccc;
}

.funkyradio input[type="radio"]:focus ~ label:before,
.funkyradio input[type="checkbox"]:focus ~ label:before {
  box-shadow: 0 0 0 3px #999;
}

.funkyradio-primary input[type="radio"]:checked ~ label:before,
.funkyradio-primary input[type="checkbox"]:checked ~ label:before {
  color: #fff;
  background-color: var(--main-color);
}
.gallery-tweet-text{
  font-size: 18px !important;
  padding-bottom: 15px;
}

.gallery-tweet-sentiment{
  font-weight: bold;
  padding: 10px;
  border-radius: 3px;
  color: white;
}

.lg-sub-html{
  top:45px;
  width: 25%;
  left: 65px!important;
  padding-top: 180px !important;
}

.lg-inner{
  width: 60% !important;
  right: 0px !important;
  left: auto !important;
}

.btn-toggle-info{
  margin-bottom: 5px;
  color: #ffffff;
  background-color: var(--main-color-trans);
}
.btn-toggle-info:hover, .btn-toggle-info:focus{
  color: #ffffff;
}

.markerContent{
  padding: 10px;
}

.markerContent .username, .tweet_simple .username{
  color: #000;
  font-weight: bold;
  font-size: 15px;
}

.markerContent .userScreenName a, .markerContent .tweetDate, .tweet_simple .userScreenName a, .tweet_simple .tweetDate{
  color: #808080;
  font-weight: bold;
  font-size: 12px;
}

.image-positivo{
  border: 3px solid #35BC7A;
}

.image-neutro{
  border: 3px solid #feda69;
}

.image-negativo{
  border: 3px solid #F54C48;
}

.markerContent .tweetText{
  word-break: break-all;
  font-size: 15px;
}

.markerContent .markerBorder-positivo, .tweet_simple .markerBorder-positivo{
  margin-right: 0;
  border-right: 5px solid #35BC7A;
}

.markerContent .markerBorder-neutro, .tweet_simple .markerBorder-neutro{
  margin-right: 0;
  border-right: 5px solid #feda69;
}

.markerContent .markerBorder-negativo, .tweet_simple .markerBorder-negativo{
  margin-right: 0;
  border-right: 5px solid #F54C48;
}

.leaflet-popup-tip-container{
  /*display: none;*/
  height: 40px;
}

/*for that the popup has been distint*/
.leaflet-popup-tip{
  /*background-color: #b2b2b2;*/
  background-color: rgb(227, 105, 48);
  width: 13px;
  height: 13px;
  margin: 10px auto 0;
  border-radius: 20px;
  transform: rotate(0);
  -webkit-transform: rotate(0);
}

.closePopupBtn{
  background-color: #e36930;
  border-color: #e36930;
  position: absolute;
  right: -65px;
  top: calc(20%);
  border-radius: 25px;
  width: 50px;
  height: 50px;
}

.closePopupBtn:hover{
  background-color: #B55426;
  border-color: #B55426;
}

.closePopupBtn i{
  font-size: 18px;
  color: #ffffff;
}

.popupTweetBtn{
  cursor: pointer;
  margin-left: 20px;
}

.wsContainer{
  position: absolute;
  /*right: -135px;*/
  right: -178px;
  top: calc(80%);
  background-color: #ffffff;
  padding: 10px 0 10px 10px;
  border-radius: 10px;
}

/*new for the servicios of the popups list*/
.wsContainerPopup{
  position: absolute;
  /*right: -105px;*/
  right: -153px;
  top: 25px;
  background-color: #ffffff;
  padding: 10px 0 10px 10px;
  border-radius: 10px;
  z-index: 2;
}
/*end new*/
/*new for the servicios of the popups list*/
.wsContainerList{
  position: absolute;
  top: calc(80%);
  left: calc(100% - 188px);
  background-color: #ffffff;
  padding: 10px 0 10px 10px;
  border-radius: 10px;
  z-index: 2;
}
/*end new*/

.clusterTextPosition{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.clusterText{
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  font-weight: bolder;
  font-size: 130%;
  color: white;
}

/*.realTimeTweetList{
  overflow: auto;
  max-height: 100%;
  overflow-x: hidden;
  padding-right: 5px;
}*/
.realTimeTweetList{
  position: absolute;
  left: 50px;
  bottom: 30px;
  z-index: 2;
  width: 600px;
}

.realTimeTweetContainer{
  overflow: auto;
  max-height: 600px;
  overflow-x: hidden;
  margin-right: -5px;
}

.realTimeTweetContainer .markerContent{
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 5px;
}

.realTimeTweetContainer.has-scroll{
  margin-right: -15px;
}

.realTimeTweetContainer::-webkit-scrollbar{
  background-color: #ffffff;
  border-radius: 20px;
  width: 10px;
}

.realTimeTweetContainer::-webkit-scrollbar-track{
  border-radius: 20px;
}

.realTimeTweetContainer::-webkit-scrollbar-thumb{
  background-color: #A6A6A6;
  border: 2px solid #ffffff;
  border-radius: 10px;
}

.gallery-modal::-webkit-scrollbar{
  background-color: #ffffff;
  border-radius: 20px;
  width: 10px;
}

.gallery-modal::-webkit-scrollbar-track{
  border-radius: 20px;
}

.gallery-modal::-webkit-scrollbar-thumb{
  background-color: #A6A6A6;
  border: 2px solid #ffffff;
  border-radius: 10px;
}

.gridMedia {
  -webkit-column-width:250px;
  -moz-column-width:250px;
  column-width:250px;
  -webkit-column-gap:10px;
  -moz-column-gap:10px;
  column-gap:10px;
}

.mediaElement {
  display:inline-block; /* prevent unexpected item cropping */
  background-color: #ffffff;
  margin:0 0 10px 0;
  padding:10px;
}

.mediaFooter{
  margin-top: 5px;
  color: #000000;
  font-size: 15px;
  font-weight: bold;
}

.mediaFooter .mediaText{
  word-break: break-all;
  font-weight: normal;
  font-size: 13px;
}

@media only screen
and (min-device-width : 1440px){
  .modal-lg{
    width: 1200px;
  }
}

/*css for donutPie*/
#chartdiv {
  font-size : 10px;
  /*max-height: 400px;*/
}

.donutPie {
  width   : 100%;
  /*height  : 400px;*/
}

.toolTip {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    position: absolute;
    display: none;
    width: auto;
    height: auto;
    background: none repeat scroll 0 0 white;
    border: 0 none;
    border-radius: 8px 8px 8px 8px;
    box-shadow: -3px 3px 15px #888888;
    color: black;
    font: 12px sans-serif;
    padding: 5px;
    text-align: center;
}

.labels{
  font: 18px sans-serif;
}

.wsServices{
  /*max-width: 158px;*/
  width: 156px;
  word-break: break-all;
}

.wsServices:hover{
  cursor: pointer;
  background-color: lightgray;
}

.wsServicesList{
  /*max-width: 158px;*/
  width: 173px;
  word-break: break-all;
}

.wsServicesList:hover{
  cursor: pointer;
  background-color: lightgray;
}

/* The container */
.checkContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkContainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkContainer input:checked ~ .checkmark {
  background-color: var(--main-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkContainer .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.logoContainer{
  position: absolute;
  top: 20px;
  left: 70%;
  z-index: 4;
  width: 40%;
}


/*collapse of real time*/
.collapseView{
  position: absolute;
  left: 50px;
  bottom: 30px;
  z-index: 2;
}
.collapseView .limit{
  width: 600px ; 
  margin-right: 15px;
}

.realTimeTweet{
  background-color: #ffffff;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 12px;
  pointer-events: none;
}

.realTimeFooter{
  background: linear-gradient(45deg,  transparent 15px, #b2b2b2 0) bottom left;
  color: #e36930;
  font-weight: bold;
  font-size: 15px;
  padding: 15px 10px 15px 10px;
  margin-top: 5px;
  margin-right: 10px;
}

.stopRealTimeBtn{
  background-color: transparent;
  border: none;
  font-size: 16px;
}

/*Boton for list real time*/
.hideCollapseViewBtn{
  background-color: #b2b2b2;
  border-color: #b2b2b2;
  position: absolute;
  border-radius: 25px;
  width: 50px;
  height: 50px;
  bottom: 0;
  right: -50px;
}

.hideCollapseViewBtn:hover{
  background-color: #8e8e8e;
  border-color: #8e8e8e;
}

.hideCollapseViewBtn i{
  font-size: 18px;
  color: #e36930;
}

/*Boton for collapse list real time*/

.hideRealTimeBtn{
  background-color: #b2b2b2;
  border-color: #b2b2b2;
  position: absolute;
  border-radius: 25px;
  width: 50px;
  height: 50px;
  bottom: 0;
  right: -50px;
}

.hideRealTimeBtn:hover{
  background-color: #8e8e8e;
  border-color: #8e8e8e;
}

.hideRealTimeBtn i{
  font-size: 18px;
  color: #e36930;
}

.lastSearchesBar{
  top: 44.2px;
  position: fixed;
  z-index: 3;
  font-size: 13px;
}

.lastSearchesBar .lastSearchesbarLimit{
  margin-left: 1px;
  margin-right: 41px;
}

.lastSearchesBar .listSearchHitoric{
  border-top-style: outset;
  border-top-width: 1px;
  background-color: white;
}

.lastSearchesBar .listSearchHitoric:hover{
  cursor: pointer;
  background-color: lightgray;
}

.lastSearchesBar .buttonSearchHitoric{
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: white;
}

.searchFilterBar{
  top: 44.2px;
  left: 30.3%;
  position: fixed;
  z-index: 3;
  font-size: 13px;
}

.searchFilterBar .bottomRowFilter{
  height: 55px !important;
}

.searchFilterBar .searchFilterbarLimit{
  margin-left:  0px;
  margin-right: 3px;
}

.searchFilterBar .searchFilterTitleBar{
  background: white;
  height: 40px;
  margin: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 7px;
  padding-top: 18px;
}

.searchFilterBar .searchFilterDataBarLeft{
  background: white;
  height: 40px;
  margin: 0px;
  padding-top: 11px;
  padding-right: 2px;
  padding-left: 3px;
  padding-bottom: 0px;
}

.searchFilterBar .searchFilterDataBarRight{
  background: white;
  height: 40px;
  margin: 0px;
  padding-top: 11px;
  padding-right: 10px;
  padding-left: 0px;
  padding-bottom: 0px;
}

.searchFilterBar .searchFilterDataBarCenter{
  background: white;
  height: 40px;
  margin: 0px;
  padding-top: 0px;
  padding-right: 12px;
  padding-left: 2px;
  padding-bottom: 0px;
}

.filterbtn{
  background: var(--secondary-color);
  color: #fff;
}

.filterbtn:hover,
.filterbtn:focus,
.filterbtn:active,
.filterbtn.active{
  background: var(--secondary-color);
  color: #fff;
}


.searchbtn{
  background: #30aae3;
  color: #fff;
}

.searchbtn:hover,
.searchbtn:focus,
.searchbtn:active,
.searchbtn.active{
  background: #30aae3;
  color: #fff;
}


.searchTitleTypes{
  font-size: 20px;
  color: black;
  font-weight: bold;
  padding-bottom: 10px;
}

.searchExampleTypes{
  font-weight: bold;
  padding-top: 15px;
}

.serveralSearchExampleTypes{
  font-weight: bold;
  padding-top: 37px;
}
